import jQuery from 'jquery'

//window.jQuery = window.$ = jQuery;
import bootstrap from 'bootstrap/js/src/index'
//STYLE
import style from './styles/index.scss'




jQuery(document).ready(function ($) {

});

